export default {
  required: "Ce champ est requis",
  isEmail: "L'email n'est pas valide",
  isNumber: "veuillez renseigner une valeur numérique",
  isFloat: "veuillez renseigner une valeur décimale",
  isPasswordMatch: "Les mots de passe ne se corresponds pas",
  successfullyUpdated: "Mise à jour réussie",
  successfullyAdded: "Ajouté avec succès",
  allFieldsRequired: "Tous les champs sont obligatoire",
  loginError: "Email ou mot de passe incorrect",
  processFinished: "Traitement terminé",
};
