import React, { useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Typography from "@material-ui/core/Typography";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Typography variant="h5">
            Bienvenue au backoffice bcloud. {state.lang}
          </Typography>
        </GridItem>
      </GridContainer>
    </div>
  );
}
